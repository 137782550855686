/* @import url('https://fonts.googleapis.com/css2?family=Langar&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sniglet&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400;700&display=swap'); 

@font-face {
  font-family: 'Helvetica Neue';
  src:  url('./fonts/HelveticaNeueMedium.ttf') format('truetype'),
      url('./fonts/HelveticaNeueBold.ttf') format('truetype'),
      url('./fonts/HelveticaNeueLight.ttf') format('truetype')
    }*/

/* HELVETICA NEUE */
@font-face {
  font-family: 'Helvetica Neue Regular';
  src:  url('./fonts/HelveticaNeueMedium.ttf') format('truetype');
  font-weight: normal;
    }

    @font-face {
    font-family: 'Helvetica Neue Bold';
    src: url('./fonts/HelveticaNeueBold.ttf') format('truetype');
    font-weight: bold;
    }
    @font-face {
    font-family: 'Helvetica Neue Light';
   src: url('./fonts/HelveticaNeueLight.ttf') format('truetype');
      font-weight: lighter;
    }



/* Caveat */
@font-face {
  font-family: 'Caveat Regular';
  src:  url('./fonts/fontGoogle/Caveat-Medium.ttf') format('truetype');
   font-weight: normal;
    }

    @font-face {
  font-family: 'Caveat Bold';
  src:  url('./fonts/fontGoogle/Caveat-Bold.ttf') format('truetype');
      font-weight: bold;
    }

    @font-face {
      font-family: 'Caveat Light';
      src:  url('./fonts/fontGoogle/Caveat-Regular.ttf') format('truetype');
      font-weight: lighter;
    }


/* DMSerifText */
@font-face {
  font-family: 'DMSerifText Regular';
  src:  url('./fonts/fontGoogle/DMSerifText-Regular.ttf') format('truetype');
    font-weight: normal;
    }


    
/* Dosis */
@font-face {
  font-family: 'Dosis Regular';
  src:  url('./fonts/fontGoogle/Dosis-Medium.ttf') format('truetype');
      font-weight: normal;
    }

    @font-face {
  font-family: 'Dosis Bold';
    src:  url('./fonts/fontGoogle/Dosis-Bold.ttf') format('truetype');
      font-weight: bold;
    }

    @font-face {
   font-family: 'Dosis Light';
      src:  url('./fonts/fontGoogle/Dosis-Light.ttf') format('truetype');
      font-weight: lighter;
    }

    
/* Montserrat */
@font-face {
  font-family: 'Montserrat Regular';
  src:  url('./fonts/fontGoogle/Montserrat-Medium.ttf') format('truetype');
      font-weight: normal;
    }

    @font-face {
  font-family: 'Montserrat Bold';
    src:  url('./fonts/fontGoogle/Montserrat-Bold.ttf') format('truetype');
      font-weight: bold;
    }

    @font-face {
   font-family: 'Montserrat Light';
      src:  url('./fonts/fontGoogle/Montserrat-Light.ttf') format('truetype');
      font-weight: lighter;
    }


/* MarkScript */
@font-face {
  font-family: 'MarkScript';
  src:  url('./fonts/fontGoogle/MarckScript-Regular.ttf') format('truetype');
      font-weight: normal;
    }

   



/* MPLUSRounded1c */
@font-face {
  font-family: 'MPLUSRounded1c Regular';
  src:  url('./fonts/fontGoogle/MPLUSRounded1c-Medium.ttf') format('truetype');
      font-weight: normal;
    }

    @font-face {
  font-family: 'MPLUSRounded1c Bold';
    src:  url('./fonts/fontGoogle/MPLUSRounded1c-Bold.ttf') format('truetype');
      font-weight: bold;
    }

    @font-face {
   font-family: 'MPLUSRounded1c Light';
      src:  url('./fonts/fontGoogle/MPLUSRounded1c-Light.ttf') format('truetype');
      font-weight: lighter;
    }


/* OpenSans-Regular */
@font-face {
  font-family: 'OpenSans Regular';
  src:  url('./fonts/fontGoogle/OpenSans-Regular.ttf') format('truetype');
      font-weight: normal;
    }

    @font-face {
  font-family: 'OpenSans Bold';
    src:  url('./fonts/fontGoogle/OpenSans-Bold.ttf') format('truetype');
      font-weight: bold;
    }

    @font-face {
   font-family: 'OpenSans Light';
      src:  url('./fonts/fontGoogle/OpenSans-Light.ttf') format('truetype');
      font-weight: lighter;
    }
/* PermanentMarker */
@font-face {
  font-family: 'PermanentMarker';
  src:  url('./fonts/fontGoogle/PermanentMarker-Regular.ttf') format('truetype');
      font-weight: normal;
    }

/* Roboto  */
@font-face {
  font-family: 'Roboto Regular';
  src:  url('./fonts/fontGoogle/Roboto-Medium.ttf') format('truetype');
      font-weight: normal;
    }

    @font-face {
  font-family: 'Roboto Bold';
    src:  url('./fonts/fontGoogle/Roboto-Bold.ttf') format('truetype');
      font-weight: bold;
    }

    @font-face {
   font-family: 'Roboto Light';
      src:  url('./fonts/fontGoogle/Roboto-Light.ttf') format('truetype');
      font-weight: lighter;
    }
/* Roboto Condensed  */
@font-face {
  font-family: 'RobotoCondensed Regular';
  src:  url('./fonts/fontGoogle/RobotoCondensed-Regular.ttf') format('truetype');
      font-weight: normal;
    }

    @font-face {
  font-family: 'RobotoCondensed Bold';
    src:  url('./fonts/fontGoogle/RobotoCondensed-Bold.ttf') format('truetype');
      font-weight: bold;
    }

    @font-face {
   font-family: 'RobotoCondensed Light';
      src:  url('./fonts/fontGoogle/RobotoCondensed-Light.ttf') format('truetype');
      font-weight: lighter;
    }

/* Arial */

      @font-face {
    font-family: 'Arial';
    src:  url('./fonts/ARIAL.TTF') format('truetype');
      font-weight: normal;
    }

       @font-face {
    font-family: 'Arial Bold';
    src:  url('./fonts/ARIALBD.TTF') format('truetype');
       font-weight: bold;
    }
       @font-face {
    font-family: 'Arial Light';
    src:  url('./fonts/ARIALLGT.TTF') format('truetype');
      font-weight: lighter;
    }


    /* Kalam */
      @font-face {
    font-family: 'Kalam Regular';
    src:  url('./fonts/fontGoogle/Kalam-Regular.ttf') format('truetype');
      font-weight: normal;
    }

       @font-face {
    font-family: 'Kalam Bold';
    src:  url('./fonts/fontGoogle/Kalam-Bold.ttf') format('truetype');
       font-weight: bold;
    }
       @font-face {
    font-family: 'Kalam Light';
    src:  url('./fonts/fontGoogle/Kalam-Light.ttf') format('truetype');
      font-weight: lighter;
    }


       /* Avenir */
       @font-face {
        font-family: 'Avenir Regular';
        src:  url('./fonts/AvenirNextCyr-Medium.ttf') format('truetype');
          font-weight: normal;
        }
    
           @font-face {
        font-family: 'Avenir Bold';
        src:  url('./fonts/AvenirNextCyr-Bold.ttf') format('truetype');
           font-weight: bold;
        }
           @font-face {
        font-family: 'Avenir Light';
        src:  url('./fonts/AvenirNextCyr-Regular.ttf') format('truetype');
          font-weight: lighter;
        }

       /* CoveredByYourGrace */
      @font-face {
    font-family: 'CoveredByYourGrace Regular';
    src:  url('./fonts/fontGoogle/CoveredByYourGrace-Regular.ttf') format('truetype');
      font-weight: normal;
    }



   /*   FONT FROM ALDEBARAN WEB FO THE PROMO*/
   @font-face {
    font-family: "Open Sans";
    src: url("./fonts/fontAldebaranPromo/OpenSans/OpenSans-Bold.ttf") format("truetype")
}
@font-face {
    font-family: "Open Sans";
    src: url("./fonts/fontAldebaranPromo/OpenSans/OpenSans-Light.ttf") format("truetype")
}

@font-face {
    font-family: "Avenir";
    src: url("./fonts/fontAldebaranPromo/Avenir/AvenirNextCyr-Bold.ttf") format("truetype")
}
@font-face {
    font-family: "Avenir";
    src: url("./fonts/fontAldebaranPromo/Avenir/AvenirNextCyr-Regular.ttf") format("truetype")
}

@font-face {
    font-family: "Helvetica Neue";
    src: url("./fonts/fontAldebaranPromo/HelveticaNeue/HelveticaNeueBold.ttf") format("truetype")
}
@font-face {
    font-family: "Helvetica Neue";
    src: url("./fonts/fontAldebaranPromo/HelveticaNeue/HelveticaNeue-Light.otf") format("truetype")
}

@font-face {
    font-family: "Montserrat";
    src: url("./fonts/fontAldebaranPromo/Montserrant/Montserrat-ExtraBold.ttf") format("truetype")
}
@font-face {
    font-family: "Montserrat";
    src: url("./fonts/fontAldebaranPromo/Montserrant/Montserrat-Regular.ttf") format("truetype")
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/fontAldebaranPromo/Roboto/Roboto-Black.ttf") format("truetype")
}
@font-face {
    font-family: "Roboto";
    src: url("./fonts/fontAldebaranPromo/Roboto/Roboto-Medium.ttf") format("truetype")
}
@font-face {
    font-family: "Roboto";
    src: url("./fonts/fontAldebaranPromo/Roboto/Roboto-Light.ttf") format("truetype")
}