@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,600;1,900&display=swap");
body {
  margin: 0;
  font-family: "Montserrat", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html body {
  height: 100%;
  background-color: #d0d6df;
}
#root {
  min-width: 100%;
  width: fit-content;
  height: 100vh;
}

:root {
  --dark_bg: #1e1e33;
  --dark_outline: #3d3d54;
  --white: #ffffff;
  --accent: #17c1c1;
  --accenthov: #26dac9;
  --error: #d3234f;
  --lightgray: #f2f4f6;
  --gray: #d0d6df;
  --body_color: #6f6f82;
}

body .text_accent {
  color: var(--accent);
}
body .text_white {
  color: var(--white);
}

body .text_error {
  color: var(--error);
}
body .bg_error {
  background-color: var(--error);
}

body .bg_dark {
  background-color: var(--dark_bg);
}
body .bg_dark_outline {
  background-color: var(--dark_outline);
}
body .bg_accent {
  background-color: var(--accent);
}
body .bg_white {
  background-color: var(--white);
}

.newshadow {
  box-shadow: 0px 4px 7px 0px #1e1e3382;
}
.corner_radius {
  border-radius: 0.25rem;
}

input.form-control {
  background-color: #f2f4f6;
  border-color: #d0d6df;
}

input.form-control:focus {
  color: #495057;
  background-color: #f2f4f6;
  border-color: var(--accent);
  outline: 0;
  box-shadow: 0 0 0 0.2rem #17c1c140;
}
button.btn-primary {
  border: var(--accenthov);
  background-color: var(--accent);
}

button.btn-primary:not(:disabled):not(.disabled).active,
button.btn-primary:not(:disabled):not(.disabled):active,
.show > button.btn-primary.dropdown-toggle {
  background-color: var(--accent);
  border: var(--accenthov);
}

button.btn-primary.focus,
button.btn-primary:focus {
  background-color: var(--accent);
  border: var(--accenthov);
  box-shadow: 0 0 0 0.2rem #17c1c140;
}

button.btn-primary:hover {
  background-color: var(--accenthov);
  border: var(--accenthov);
}
button.btn-primary.disabled,
button.btn-primary:disabled {
  color: #fff;
  opacity: 0.4;
  background-color: var(--accenthov);
  border: var(--accenthov);
}

.toolbox__items {
  display: block;
}
.toolbox__items__item {
  display: inline-block;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  width: 80px;
  height: 80px;
  padding: 10px;
  margin: 5px;
  border: 1px solid black;
  background: #ccc;
}

/* CIRCLE BUTTON */
button.btn.circlebutton {
  border: solid 1px #00000036;
  border-radius: 100%;
  box-shadow: -1px 2px 5px 0px #00000080;
  z-index: 10;
}

button.circlebutton.btn.btn-danger {
  top: -12px;
  position: absolute;
  padding: 0.188rem 0.375rem;
  left: -0.35rem;
  line-height: 0.5;
}

button.circlebutton.btn.btn-primary {
  top: 1.5rem;
  position: absolute;
  left: -1.2rem;
}
button.circlebutton.btn.btn-info {
  top: 4.5rem;
  position: absolute;
  left: -1.2rem;
}

/* ANIMATION LEFT AND RIDE SIDE MENU */
/* ANIMATION SIDE MENU RIGHT  */
.sideMenu {
  transition: transform 0.3s ease-out;
  transform: translate(0, 0);
}
.sideMenu.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(200px, 0);
}
.sideMenu.modal.show .modal-dialog {
  box-shadow: -11px 0px 20px 6px #000000e0;
  transform: none;
}
.sideMenu.modal.fade.modal-static .modal-dialog {
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
}
.sideMenu h6.warning {
  color: var(--error);
}
/* ANIMATION SIDE MENU LEFT TOOLBOX */
.sideToolbox {
  transition: transform 0.3s ease-out;
  transform: translate(0, 0);
}
.sideToolbox.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(-200px, 0);
}
.sideToolbox.modal.show .modal-dialog {
  transform: none;
}
/* ANIMATION ITEM ON DTHE GRID */
@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.react-grid-item.cssTransforms.react-draggable {
  animation-name: opacity;
  animation-duration: 0.8s;
}

/* SIDE TOOLBOX */
.backdrop_sideToolbox.modal-backdrop.show {
  opacity: 0.2;
  background-color: #fff;
}
.sideToolbox .modal-dialog {
  max-height: 100%;
  margin-right: 0;
  margin-top: 0;
  height: 100%;
  margin-bottom: 0;
}
.sideToolbox .modal-content {
  background-color: var(--dark_bg);
  border: 1px solid var(--dark_outline);
  box-shadow: 0px 4px 7px 0px #1e1e3382;
  color: var(--white);
  border-radius: 0 0.3rem 0.3rem 0;
}
.sideToolbox .modal-header {
  background-color: #17c1c1;
  border-bottom: 1px solid var(--dark_outline);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.sideToolbox .modal-footer {
  border-top: 1px solid var(--dark_outline);
}
.sideToolbox .modal-dialog-scrollable .modal-content {
  max-height: 100%;
}
/* SIDE MENU */
.backdrop_sideMenu.modal-backdrop.show {
  opacity: 0;
  background-color: #fff;
}
.sideMenu .modal-dialog {
  margin-right: 0;
  margin-top: 0;
  height: 100%;
  margin-bottom: 0;
  max-height: 100%;
}

.sideMenu .modal-dialog-scrollable .modal-content {
  max-height: 100%;
}

.sideMenu .modal-content {
  background-color: var(--dark_bg);
  border: 1px solid var(--dark_outline);
  box-shadow: 4px 0px 7px 0px #1e1e3382;
  color: var(--white);
  border-radius: 0.3rem 0 0 0.3rem;
}

.sideMenu .form-control:disabled,
.form-control[readonly] {
  background-color: #f0f0f0;
  opacity: 0.2;
}

 .sideMenu .modal-header {
  background-color: #17c1c1;
  border-bottom: 1px solid var(--dark_outline);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

 .sideMenu .modal-footer {
  border-top: 1px solid var(--dark_outline);
}


/*  NEW TOOLBOX DIALOG */

#ToolbooxMenu.bg-modal{
  height: 100vh;
  position: fixed;
  z-index: 11111111111;
  background-color: #e6e6e6bf;
  width: 100%;
  top: 0;
  left: 0;
}

  #ToolbooxMenu .modal-dialog {
    margin:0
  }


/*  COMPONENT */
.component_logoimg {
  object-fit: contain;
}
/* MODAL */
.backdrop_modal.modal-backdrop {
  background-color: var(--gray);
}
.backdrop_modal.modal-backdrop.show {
  opacity: 0.9;
}
.modal_dark .modal-content {
  background-color: var(--dark_bg);
  border: 1px solid var(--dark_outline);
  box-shadow: 0px 4px 7px 0px #1e1e3382;
  color: var(--white);
}

.modal_dark .navbar-nav .nav-link:hover {
  color: var(--accent);
}
.modal_dark .modal-header {
  border-bottom: 1px solid var(--dark_outline);
}
.modal_dark .modal-footer {
  border-top: 1px solid var(--dark_outline);
}
.modal_dark .close {
  color: var(--white);
  opacity: 1;
}
.modal_dark .close:hover {
  color: var(--white);
}

/* Login*/
.header_login {
  width: 100%;
  text-align: center;
  padding: 15px;
  border-radius: 0.25rem 0.25rem 0 0;
}

.body_login {
  width: 100%;
  text-align: center;
  padding: 15px;
  border-radius: 0 0 0.25rem 0.25rem;
}
.aldebran_title h1 {
  line-height: 0.1;
  font-size: 5em;
  color: var(--dark_outline);
  font-weight: 700;
}
.aldebran_title h2 {
  margin-top: 20px;
  color: var(--accent);
  font-weight: 400;
}
.aldebran_title h5 {
  margin-bottom: 60px;
  color: var(--gray);
  font-weight: 300;
  letter-spacing: 0.2em;
  line-height: 0;
}

.aldebran_title h6 {
  margin-top: 90px;
  color: var(--gray);
  font-weight: 300;
  letter-spacing: 0.2em;
  line-height: 1;
}

/* Header*/
.bg_dark .dropdown-menu {
  font-size: 1rem;
  list-style: none;
  background-color: var(--dark_bg);
  border: 1px solid var(--dark_outline);
  border-radius: 0.25rem;
}

.bg_dark .dropdown-item,
.bg_dark .dropdown-item {
  color: var(--white);
  background-color: var(--dark_bg);
}

.bg_dark .dropdown-item:focus,
.bg_dark .dropdown-item:hover {
  color: var(--accenthov);
  background-color: var(--dark_bg);
}

.bg_dark .dropdown-item.active,
.bg_dark .dropdown-item:active {
  color: var(--accent);
  background-color: var(--dark_bg);
}
.bg_dark.navbar-light .navbar-nav .active > .nav-link,
.bg_dark.navbar-light .navbar-nav .nav-link.active,
.bg_dark.navbar-light .navbar-nav .nav-link.show,
.bg_dark.navbar-light .navbar-nav .show > .nav-link {
  color: var(--white);
}
.bg_dark.navbar-light .navbar-nav .nav-link {
  color: var(--white);
}

.navbar-light.bg_dark .navbar-nav .nav-link.disabled {
  color: var(--dark_outline);
}
.navbar-light.bg_dark .navbar-nav .nav-link:hover {
  color: var(--accent);
}

.navbar-light.bg_dark .navbar-nav .nav-link:focus,
.navbar-light.bg_dark .navbar-nav .nav-link:hover {
  color: var(--accent);
}

/* MODAL LIST*/

.modal_dark .list-group-item {
  background-color: var(--dark_bg);
  color: var(--white);
}

.modal_dark .list-group-item  {
  border:1px solid var(--dark_outline);
}

.modal_dark .list-group-item-action:hover {
  background-color: var(--accent);
  color: var(--white);
}

.modal_dark .list-group-item-action:focus {
  background-color: var(--accenthov);
}



/*SAVE MODAL */
/* Pagination */
body .page-link{
  color:var(--dark_outline);
}
body .page-item.active .page-link{
  background-color: var(--accent);
  border-color: var(--accent);
}
/* List display */
body .list-group-item.active {
  background-color: var(--accent);
  border-color: var(--accent);
}
/* grid drag */

.container_gridlayout {
  box-shadow: 1px 2px 8px 1px #0000005e;
  background-color: var(--body_color);
  padding: 20px;
  width: fit-content;
  margin: auto;
  overflow: hidden;
}

.react-grid-layout.landscape {
  overflow: visible;
  background-color: #fff;
  margin: auto;
  width: 1920px;
  height: 1080px !important;
  min-height: 1080px;
  max-height: 1080px;
}

.react-grid-layout.portrait {
  overflow: visible;
  background-color: #fff;
  margin: auto;
  width: 1080px;
  height: 1920px !important;
  min-height: 1920px;
  max-height: 1920px;
}

.sideToolbox .modal-dialog {
  margin-left: 0;
  margin-top: 0;
  height: 100%;
  margin-bottom: 0;
}

/* Toolbox*/
.toolbox {
  background-color: transparent;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.toolbox__items__item {
  display: block;
  text-align: left;
  line-height: inherit;
  cursor: pointer;
  width: auto;
  height: auto;
  padding: 10px;
  margin: 5px;
  border: none;
  background: transparent;
  text-transform: uppercase;
}

/* Container base*/
.container_component {
  width: 100%;
  height: 100%;
  transition: all 250ms ease;
  display: flex;
}
/* TITLEBAR*/

.icon_container {
  height: 100%;
}
.text_title h1 {
  line-height: 0;
  width: max-content;
  font-size: inherit;
  align-self: center;
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 12px;
}
.text_title {
  overflow: hidden;
}
/*  PROMO CAROUSEL */
.promo {
  border: 1px solid #d6d6d6;
  padding: 5px;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}
.promo .carousel {
  height: 100%;
}

.promo .carousel-inner {
  height: 100%;
}

.promo .carousel-item {
  height: 100%;
}
.promo .promo_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/* TOAST */
/* SAVING TOAST */
#SavingToast {
  position: fixed;
  z-index: 1000000;
  bottom: 0;
  right: 0;
}

#SavingToast .toast-body {
  color: var(--white);
  background-color: var(--dark_bg);
  border: 1px solid var(--dark_outline);
}

#SavedToast {
  position: fixed;
  z-index: 1000000;
  bottom: 0;
  right: 0;
}

#ErrorToast {
  position: fixed;
  z-index: 1000000;
  bottom: 0;
  right: 0;
}
/* PROGRESS */
/* body .progress_container{
  display: flex;
  width: 100%;
  min-height: 120px;
  align-items: center;
} */

body  .progress_container{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}



body .progress {
  display: flex;
  height: 0.7rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #ffffff;
  border-radius: 0.25rem;
  border: 1px solid #ababab;
}

body .progress .progress-bar{
  background-color: #ffffff;
}

body .progress.progress-rectangle{
  margin:auto 220px auto 40px ;
  width: 100%;
}
.circle-fluidity{
  background-color: #d6d6d6;
  width: 150px;
  height: 150px;
  max-width: 140px;
  padding: 0;
  display: block;
  position: absolute;
  right: 40px;
  border-radius: 100%;
  bottom: -50px;
  box-shadow: 1px 2px 8px 1px #0000005e;
}

/* INFOFLUIDITY */
.listcontainer-infoFluidity{
flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

.number-infoFluidity{
  font-weight: bold;
  font-family: 'Montserrat';
  color: #282c2d;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 2.3em;
  display: flex;
  justify-content: center;
  align-items: center;}
  .textcontainer-infoFluidity{  
    border-bottom: 3px solid #d6d6d6;
    height: 100%;
  align-self: center;}

  .item-infoFluidity:nth-last-child(1)  .textcontainer-infoFluidity{
    border-bottom: none;
  }

  .item-infoFluidity{
    margin-bottom: 15px;
  }

.noresize .react-resizable-handle {
  display: none;
}

.multiFoodGroupList div:last-child {
  border: none;
}
.multiFoodGroupList div  {
  border-bottom: 1px solid #d6d6d6;
}

/* NEW */
.slide-demo{
  height: 100%;
  background-color: #ffffff;
  display: flex;
  place-items: center;
  justify-content: center;
}

.slide-demo h2{
  text-align: center;
  color: #2e2e2e;
  font-size: 3em;
}
.resizeSVG svg {
  width: 100%;
  height: 100%;
}
.resizeSVGImageFoodGroup svg {
  min-height: 100%;
  width: 100%;
  max-height: 400px;
  flex-shrink: 0;
  display: flex;
  height: 0;
  padding: 30px;
  }
.svgTitle {
width: 100%;
height: 100%;
}
.iconWeather svg{
  height: 100%;
  width: auto;
}

.itemCardFg:nth-last-child(1){
 margin-bottom:20px;
}
.itemCardFg{
 margin-bottom:0;
}
.containerCardFg{
    width: calc(100% - 110px);
     
  /* height: 100%; */
}

  .imageCarousel {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.video-section{
height: 100%;
    background-color: #282c2d;}

.video-section embed video{  

    width: 100%;
    height: 100%;}

    .textpromo-title{
      display: block;
      width: 100%;
      text-align: center;
    }

    .slide-textpromo{
  display: flex;
    height: 100%;
      width: 100%;
      flex-direction: column;
      text-align: center;
      justify-content: center;
    }